<template>
    <div style="padding-bottom:2rem;">
      <div class="text-center animate__animated animate__zoomIn blue--text heading">
        <p>{{"Land as a Hedge against Inflation"}}</p>
      </div>
      <v-row class="no-gutters body-text">
        <v-col class="offset-2 col-8">
            People naturally pay more interest to alarming news; this can be explained from an evolutionary stand point where the need to be 
            keen on assumed imminent threats was vital to survival. With this knowledge one may argue that the current panic about inflation is 
            nothing more than hot air exacerbated by the phenomenon elaborated earlier. However, numbers do not lie, the Kenya National Bureau of
            statistics announced an acceleration in the country’s annual inflation rate to 7.9% in the month of June, 2022 which signified a 0.8%
            increase from just a month earlier.
        </v-col>
        <v-col class="offset-4 col-4 d-flex justify-center">
          <v-img class="rounded lighten-4"
          contain
          :src="require(`../assets/infation.jpg`)"
          />
        </v-col>
        <v-col class="offset-2 col-8">
            In a layman’s understanding this means that if you have, say, 1000KES today, then it will be 
            worth about 920KES in a year’s time. This is a worrying prospect if further compounded over a longer period. So, is money worthless 
            now? No but yes, in a way. No since it still a measure of value and may be used to transfer the said value, however, 
            it is not particularly the best medium to store value anymore, it is not a shrewd decision to ‘put your money under the mattress.’ 
        </v-col>
        <v-col class="offset-4 col-4 d-flex justify-center">
          <v-img class="rounded lighten-4"
          contain
          :src="require(`../assets/1000T.jpg`)"
          />
        </v-col>
        <v-col class="offset-2 col-8">
            One needs to consider an extreme case of inflation like that of Zimbabwe to unravel this fact, the country had an 
            inflation rate of 191% in the month of June, 2022; this pushed the Zimbabwean government to intervene and provide a 
            different medium of storing value and introduced gold coins. Admittedly, Kenya is unlikely to get to such an extreme case 
            to necessitate the rolling out of gold coins but one may still shield themselves against the pangs of inflation by 
            sourcing for alternate means to store value. Your best bet in this is in acquiring land. 
        </v-col>
        <v-col class="offset-2 col-8">
          <p class="blue--text mt-2 sub-heading">{{"Using Land as A Hedging Asset"}}</p>
        </v-col>
        <v-col class="offset-2 col-8">
            A hedge is a means of protecting oneself from financial loses or other adverse financial circumstances. Acquiring none depreciating assets is one of the most 
            common and effective ways to shield one’s finances. Land is one such appreciating asset; its supply is constant but its demand increases with more people 
            wanting it over time, therefore the interaction of these market forces leads to an increase in its price. Admittedly, the rate of the land’s appreciation may 
            be lower than the rate of inflation in the cases where inflation is exaggeratedly high, but that is not the case with Kenya’s inflation which is at par with the
            global average of 6.9% in June 2022. In absolute terms, Land may be used as a hedge if: 
        </v-col>
        <v-col class="offset-2 col-8">
            <ul>
                <li>
                    It was bought in cash, buying land in cash is the best way to ensure that it protects you from inflation, this is opposed to buying in long period 
                    installments in which case the inflation might catch up with the payments. 
                </li>
                <li>
                    If the taxes and land rates are not too high. Luckily, this is the case in Kenya where the government charges at a rate of 0.115% of the land value. 
                    If the rate was too high then all the hedging benefits would be eroded by the rates and taxes payments.
                </li>
                <li>
                    If the land is not bought at an overprice. To optimally secure yourself against inflation using land, it is necessary that the land be bought at its optimum 
                    price. Tis is only natural since if overpriced, then the amount above the fair price might be the same as the value of inflation thus negating the hedging \
                    effect. The prices set by Adili real estate is fair and as such, buying your land trough Adili Real Estate is sure to shield you in the face of the inflation 
                    being experienced.
                </li>
            </ul>
        </v-col>
        <v-col class="offset-2 col-8">
          <p class="blue--text mt-2 sub-heading">{{"Land Liquidity"}}</p>
        </v-col>
        <v-col class="offset-4 col-4 d-flex justify-center">
          <v-img class="rounded lighten-4"
          contain
          :src="require(`../assets/liquidity.jpg`)"
          />
        </v-col>

        <v-col class="offset-2 col-8">
        Lastly, land is relatively liquid compared to other kinds of long-term investments such as government bonds. It goes without saying that inflation causes an 
        increase in demand for liquid cash to individuals due to the diminished purchasing power of money. Having land would come in handy in such a case since 
        it could easily be turned into liquid cash either by selling it at the then market price or using it as security against a bank or Sacco loan. Buy land 
        today to shield yourself against the impending inflation.
        </v-col>
      </v-row>
    </div>
  </template>
  <script>
  export default {
    name: 'landAndInflationBlog',
    data(){
      return{
        contact_data: {}
      }
    },
    methods:{
      go(code){
        window.location = code
      },
      goToSection(element_id){
        this.go('/' + element_id)
      },
      buyNow(){
        let url = "https://wa.me/+254720244744?text=I%20want%20to%20buy%20this%20land%20now!"
        window.open(url,'_blank')
      }
    }
  }
  </script>
  